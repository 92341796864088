class Goals {
  constructor(el) {
    this.el = el;
  }

  init() {
    this.initGoals();
  }
  initGoals() {
    var $goalsContent = $('#goalsContent');
    $goalsContent.on('show.bs.collapse', '.collapse', function () {
      $goalsContent.find('.collapse.show').collapse('hide');
    });
  }
}
export default Goals;
