import Sticky from './sticky';
/**
 * Responsible for the team block.
 */
class Team {
  /**
   * Constructor.
   * @param {DOMElement} el
   */
  constructor(el) {
    this.el = el;
    this.teamBlockAssistant = window.team_block_assistant || {};
    this.partnerTitle = this.teamBlockAssistant.partnerTitle;
    this.colleaguesTitle = this.teamBlockAssistant.colleaguesTitle;
  }

  /**
   * Initializes the Team Picker.
   */
  init() {
    this.initialRendering();
    this.addFilterButtonEventListener();
    this.addSearchInputEventListener();
  }

  /**
   * Makes the rendering on DOM Start.
   */
  initialRendering() {
    const members = {
      directors: this.teamBlockAssistant['teamDirectors'],
      partners: this.teamBlockAssistant['teamPartners'],
      all: [],
    };

    this.renderMembers(members);
  }

  /**
   * Gets all members from a specific member by filter.
   * @param {string} filter filter to search for.
   */
  getMembersWithFilter(filter) {
    return {
      directors: this.searchMembersWithFilter(
        filter,
        this.teamBlockAssistant['teamDirectors']
      ),
      partners: this.searchMembersWithFilter(
        filter,
        this.teamBlockAssistant['teamPartners']
      ),
      all: this.searchMembersWithFilter(
        filter,
        this.teamBlockAssistant['teamPostAll']
      ),
    };
  }

  /**
   * Gets all the members with a specific name (contains name).
   * @param {string} name Name to which to search for.
   */
  getMembersByName(name) {
    return {
      directors: this.searchMembersByName(
        name,
        this.teamBlockAssistant['teamDirectors']
      ),
      partners: this.searchMembersByName(
        name,
        this.teamBlockAssistant['teamPartners']
      ),
      all: this.searchMembersByName(
        name,
        this.teamBlockAssistant['teamPostAll']
      ),
    };
  }

  /**
   * Searches for the members from a filter name
   * @param {string} filter
   * @param {array} members
   */
  searchMembersWithFilter(filter, members) {
    if (filter === 'all') {
      return members;
    }

    return members.filter((member) => member['filters'].indexOf(filter) !== -1);
  }

  /**
   * Renders the members in specific DOM element containers.
   * @param {array} members Array of members to print
   */
  renderMembers(members) {
    const self = this;

    $('.js-partners-row').removeClass('row--border-top');
    $('.js-all-team-row').removeClass('row--border-top');

    this.renderMemberGroup(
      members.directors,
      $(this.el).find('.js-directors-row')
    );
    this.renderMemberGroup(
      members.partners,
      $(this.el).find('.js-partners-row')
    );
    this.renderMemberGroup(members.all, $(this.el).find('.js-all-team-row'));

    if (
      $('.js-partners-row').children().length > 0 &&
      $('.js-directors-row').children().length > 0
    ) {
      $('.js-partners-row').addClass('row--border-top');
      $('.js-partners-row').prepend(
        '<div class="col-md-12"><h3>' + this.partnerTitle + '</h3></div>'
      );
    }

    if (
      ($('.js-all-team-row').children().length > 0 &&
        $('.js-partners-row').children().length > 0) ||
      ($('.js-all-team-row').children().length > 0 &&
        $('.js-directors-row').children().length > 0)
    ) {
      $('.js-all-team-row').addClass('row--border-top');
      $('.js-all-team-row').prepend(
        '<div class="col-md-12"><h3>' + this.colleaguesTitle + '</h3></div>'
      );
    }

    if ($(this.el).find('.js-directors-row').children().length === 0) {
      $(this.el).find('.js-results-container h3').css({ display: 'none' });
    } else {
      $(this.el).find('.js-results-container h3').css({ display: 'block' });
    }

    /**
     * This is probably temporary if we change the bootstrap columns for mobile.
     */
    if ($(window).outerWidth() > 767) {
      const $mitarbeiterInfo = $('.mitarbeiter_info');
      const $mitarbaiterRow = $mitarbeiterInfo.closest('.row');
      $mitarbeiterInfo.css('width', $mitarbaiterRow.outerWidth() - 30);
    }

    $(this.el)
      .find('figure')
      .on('click', function (e) {
        e.preventDefault();

        if ($(this).parent('.mitarbeiter-container').hasClass('active')) {
          return;
        }

        $(self.el).find('.js-results-container').addClass('opened-mitarbeiter');

        $('.mitarbeiter_info').hide('fast');
        $('.col-md-3.active').removeClass('active');
        $('.mitarbeiter-container.active').removeClass('active');
        $('.mitarbeiter-container').css('height', 'auto');
        $(this).parent('.mitarbeiter-container').addClass('active');
        $(this)
          .parent('.mitarbeiter-container')
          .parent('.col-md-3')
          .addClass('active');

        const height =
          $(this).parent('.mitarbeiter-container').find('img').height() +
          $(this)
            .parent('.mitarbeiter-container')
            .find('.mitarbeiter_info')
            .height();

        $(this).parent('.mitarbeiter-container').css({ height: height });
        const $mitarbeiterInfo = $(this).next('.mitarbeiter_info');
        const $mitarbaiterRow = $mitarbeiterInfo.closest('.row');
        $mitarbeiterInfo.css('width', $mitarbaiterRow.outerWidth() - 30);
        $mitarbeiterInfo.toggle('fast');

        const $activeElement = $('.mitarbeiter-container.active');
        const activeElementLeft = $activeElement.offset().left;
        const parentElementLeft = $activeElement.closest('.row').offset().left;

        const left = (activeElementLeft - parentElementLeft) * -1 + 15;
        $activeElement.find('.mitarbeiter_info').css('left', left);
      });

    $(this.el)
      .find('.js-close-mitarbeiter')
      .on('click', function () {
        $('.mitarbeiter_info').hide('fast');
        $('.mitarbeiter-container').removeClass('active');
        $('.mitarbeiter-container').css('height', 'auto');
        $('.col-md-3').removeClass('active');
        $(self.el)
          .find('.js-results-container')
          .removeClass('opened-mitarbeiter');
      });

    this.addStickyNavigation();
  }

  /**
   * Renders a specific member group in a specific DOM Element.
   * @param {array} members Member group to render
   * @param {DOMElement} $element container in in which it will render
   */
  renderMemberGroup(members, $element) {
    let html = members.reduce(
      (result, member) => result + this.getMemberCard(member),
      ''
    );

    $element.empty();
    $element.append(html);
  }

  /**
   * Gets the HTML markup for a specific member.
   * @param {Object} member Member to print
   */
  getMemberCard(member) {
    return `<div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 mix">
        <div class="mix mitarbeiter-container">
          <figure class="mitarbeiter_click mitarbeiter--cursor-pointer">
            <div class="image">${member['image']}</div>
            <figcaption class="person-name">${member['name']}</figcaption>
          </figure>
          <div class="mitarbeiter_info">
            <div class="mitarbeiter_inner">
              <div class="mitarbeiter__header">
                <h3>${member['name']}</h3>
                <div class="mitarbeiter_close_btn js-close-mitarbeiter mitarbeiter--cursor-pointer">
                  <img src="${this.teamBlockAssistant.close_icon}"/>
                </div>
              </div>
              <div class="mitarbeiter__body">
                <p>${member['position']}</p>
                <p>
                  ${member['adresse']}
                </p>
                <p>
                  ${member['telefon'] ? 'T ' + member['telefon'] + '<br>' : ''}
                  ${member['telefon_2'] ? 'F ' + member['telefon_2'] : ''}
                  <br/>
                  <a href="mailto:${member['email']}">
                    ${member['email']}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>`;
  }

  /**
   * Event listener for the search input.
   */
  addSearchInputEventListener() {
    const self = this;

    $(this.el)
      .find('.js-text-filter-input')
      .on('keyup', function () {
        $(self.el)
          .find('button.filter.control-active')
          .removeClass('control-active');
        const members = self.getMembersByName($(this).val());
        $('.mitarbeiter_info').hide();
        $('#Filtercontainer').removeClass('opened-mitarbeiter');
        self.renderMembers(members);
      });
  }

  /**
   * Searches and returns members with a specific name (contains name).
   * @param {string} name Name to search for
   * @param {array} members Members to search by name
   */
  searchMembersByName(name, members) {
    return members.filter(
      (member) =>
        member['name'].toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  /**
   * Adds event listener for the filter buttons.
   */
  addFilterButtonEventListener() {
    const self = this;

    $(this.el)
      .find('button.filter')
      .on('click', function () {
        if ($(this).hasClass('control-active')) {
          $(this).removeClass('control-active');
          self.initialRendering();
          return;
        }

        $(self.el).find('#filter-input').val('');

        $(self.el)
          .find('button.filter.control-active')
          .removeClass('control-active');
        $(this).addClass('control-active');

        $('.mitarbeiter_info').hide('fast');
        $('.mitarbeiter-container').removeClass('active');
        $('.mitarbeiter-container').css('height', 'auto');
        $('.col-md-3').removeClass('active');
        $(self.el)
          .find('.js-results-container')
          .removeClass('opened-mitarbeiter');

        const members = self.getMembersWithFilter($(this).data('filter'));
        self.renderMembers(members);
      });
  }

  /**
   * Adds the sticky navigation.
   */
  addStickyNavigation() {
    new Sticky({
      el: this.el,
    }).init();
  }
}

export default Team;
