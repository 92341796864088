import Swiper from 'swiper';
class CareerTrainee {
  constructor(el) {
    this.el = el;
    this.swiper = null;
  }

  init() {
    this.initCareerTrainee();
  }
  initCareerTrainee() {
    this.swiper = new Swiper('.swiper-trainee', {
      centeredSlides: true,
      slidesPerView: 'auto',
      speed: 1500,
      autoHeight: true,
      breakpoints: {
        320: {
          spaceBetween: 10,
        },
        1200: {
          spaceBetween: 35,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}
export default CareerTrainee;
