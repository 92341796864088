class SearchForm {
  constructor(el) {
    this.el = el;
    this.onChange = this.onChange.bind(this)
  }

  init() {
    this.addEventListeners();
  }

  onChange() {
    const $input = $(this.el).find('input')
    if($input.val() !== '') {
      $(this.el).addClass('js--not-empty')
    } else {
      $(this.el).removeClass('js--not-empty')
    }
  } 

  addEventListeners() {
    const $input = $(this.el).find('input')
    
    $(this.el).find('.btn--search-clear').on('click', () => {
      $input.val('');
      $(this.el).removeClass('js--not-empty')
    })

    $(this.el).find('.js-search-toggle').on('click', () => {
      if($(this.el).hasClass('open') && $input.val()) {
        $(this.el).submit();
      }

      $(this.el).toggleClass('open');
    });

    $input.on('keydown', this.onChange)
    $input.on('keyup', this.onChange)
  }
}

export default SearchForm;
