/* eslint-disable */
class MapProject {
  constructor(el) {
    this.el = el;
  }
  init() {
    this.initMap();
  }
  initMap() {
    const elem = document.getElementById('mapproject');
    if (elem) {
      ym.ready(function (modules) {
        const mapProjectHelper = window.mapProject_block_helper || {};
        const title = mapProjectHelper.title;
        let website = mapProjectHelper.website.title;
        let websiteUrl = mapProjectHelper.website.url;
        let address = mapProjectHelper.mapProjects.address;
        const lat = parseFloat(mapProjectHelper.mapProjects.lat);
        const lng = parseFloat(mapProjectHelper.mapProjects.lng);
        let pos;

        if (pos !== false) {
          address = address.replace(/,|\//g, '<br>');
        }

        if (website == undefined) {
          website = '';
        }

        if (websiteUrl == undefined) {
          websiteUrl = '';
        }
        const contents = `<div class="card-wrapper text-black">
        <div class='card--title'>
          <h3>${title}</h3>
        </div>
        <div class='card--address'>
          <p>${address}</p>
        </div>
        <div class='card--email'>
          <a href="${websiteUrl}" target="_blank">
            ${website}
          </a>
        </div>
      </div>`;
        var map = ym.map('mapproject', {
          center: ym.latLng(lat, lng),
          zoom: 13,
          style: 'darkmode',
          scrollWheelZoom: false,
        });
        var icon = new modules.provider.Icon({
          iconUrl:
            'https://www.art-invest.de/wp-content/themes/art-invest/resources/assets/images/Map-Marker.svg',
          iconRetinaUrl:
            'https://www.art-invest.de/wp-content/themes/art-invest/resources/assets/images/Map-Marker.svg',
          iconSize: [37, 48],
          iconAnchor: [15, 49],
          popupAnchor: [0, -48],
        });
        const marker = (window.marker = new modules.provider.Marker(
          ym.latLng(lat, lng)
        ).bindPopup(contents));
        marker.setIcon(icon);
        marker.addTo(map);
      });
    }
  }
}
export default MapProject;
