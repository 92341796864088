import Swiper from 'swiper';
class CareerCosmosSlider {
  constructor(el) {
    this.el = el;
    this.swiper = null;
  }

  init() {
    this.initCareerCosmosSlider();
  }
  initCareerCosmosSlider() {
    this.swiper = new Swiper('.swiper-cosmos', {
      speed: 1500,
      breakpoints: {
        320: {
          spaceBetween: 10,
          slidesPerView: 1,
        },
        768: {
          spaceBetween: 10,
          slidesPerView: 2,
        },
        1200: {
          spaceBetween: 35,
          slidesPerView: 3,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next.cosmos',
        prevEl: '.swiper-button-prev.cosmos',
      },
    });
  }
}
export default CareerCosmosSlider;
