class CareerVueHelper {
  constructor(el) {
    this.el = el;
  }

  init() {
    this.initCareer();
    console.log('init');
  }
  initCareer() {
    window.addEventListener('popstate', function (event) {
      // Log the state data to the console
      console.log(event.state);
    });
    $(window).on('hashchange', function () {
      console.log('changed');
      if ($('.job-detail')[0]) {
        $('.k-header').hide();
      } else {
        $('.k-header').show();
      }
    });
  }
}
export default CareerVueHelper;
