import Sticky from './sticky';
import RevealEls from '../blocks/revealEls'
class PostFilter {
  constructor(el) {
    this.el = el;
    this.page = 2;
  }

  init() {
    this.presseFiltration();
    this.addStickyNavigation();
    this.createBgs();
  }
  /**
   * Adds the sticky navigation.
   */
  addStickyNavigation() {
    new Sticky({
      el: this.el,
    }).init()
  }


  presseFiltration() {
    const self = this;
    /** Button Filter */
    $(self.el).find('.button-filters  button').click(function(e) {
      e.preventDefault();

      let params = {
        action: 'presseFiltration',
      };

      if($(this).hasClass('control-active')) {
        self.category = '';
        $(this).removeClass('control-active');
      } else {
        self.category = $(this).attr('data-id');
        $(self.el).find('button.filter.control-active').removeClass('control-active');
        $(this).addClass('control-active');
      }
      if(self.category != '') {
        params.category = self.category;
      }

      $.ajax({
        url: window.ajax_object.ajax_url,
        type: 'POST',
        data: {action:'presseFiltration', category: self.category, last_year: ''},
        dataType: 'json',
        success: (response) => {
          const $container = $(self.el).find('.postFilter')
          $container.html(response.html);
          $('#last_year').val(response.last_year);
          console.log('last_year: ' + response.last_year);
          self.page = 2;
          self.createBgs();
          $(self.el).find('.js-filter-input-post').val('');

          new Sticky({
            el: self.el,
          }).repositionSticky()

          const animEl = self.el.querySelectorAll('.anim')
          new RevealEls({
            $sections: animEl,
          });
        },
      });
    });

    /** Search Filter */
    $(self.el).find('.js-filter-input-post').on('keyup', (e) => {
      $.ajax({
        url: window.ajax_object.ajax_url,
        type: 'post',
        data: {action: 'presseFiltration', keyword: $(e.target).val(), last_year: ''},
        dataType: 'json',
        success: (response) => {
          $(self.el).find('.postFilter').html(response.html);
          $('#last_year').val(response.last_year);
          self.page = 2;
          self.createBgs();
          $(self.el).find('.button-filters  button').removeClass('control-active');

          new Sticky({
            el: self.el,
          }).repositionSticky()

          const animEl = self.el.querySelectorAll('.anim')
          new RevealEls({
            $sections: animEl,
          });
        },
      });
    });

    /** Pagintaion Filter */
    $(window).scroll(() => {
      if ($(window).scrollTop() == $(document).height() - $(window).height()) {
        let searchKeyword = $(self.el).find('.mobile .js-filter-input-post').val();
        const lastYear = $('#last_year').val();

        if($(window).outerWidth() >= 1024) {
          searchKeyword = $(self.el).find('.desktop .js-filter-input-post').val();
        }

        this.data = {
          action: 'presseFiltration',
          keyword: searchKeyword,
          category: self.category,
          page: self.page,
          last_year: lastYear,
        };
        $.ajax({
          url: window.ajax_object.ajax_url,
          type: 'post',
          data: this.data,
          dataType: 'json',
          success: (response) => {
            if(response) {
              const $container = $(self.el).find('.postFilter')
              $container.append(response.html);
              $('#last_year').val(response.last_year);
              console.log('last_year: ' + response.last_year);
              self.createBgs();
              self.page++;
            }

            new Sticky({
              el: self.el,
            }).repositionSticky()

            const animEl = self.el.querySelectorAll('.anim')
            new RevealEls({
              $sections: animEl,
            });
          },
        });
      }
    });
  }

  createBgs() {
    const $container = $(this.el).find('.postFilter')
    let result = '';
    const childCount = $container.children().length;
    if(childCount) {
      $container.find('.mix').each((i) => result += this.addBg(i + 1 + childCount));
    }
  }

  addBg(index) {
    if(Number.isInteger(index / 5)) {
      let cssModifier = '';

      if(this.lastBgModifier === 'bg--right') {
        this.lastBgModifier = 'bg--left';
        cssModifier = 'bg--left';
      } else {
        cssModifier = 'bg--right';
        this.lastBgModifier = 'bg--right';
      }
      $(this.el).find('.bgs').append(`<div class="bg ${cssModifier}"></div>`);
    }
  }
}

export default PostFilter;
