/* eslint-disable */
import AOS from 'aos';
import slider from '../blocks/banner';
import CoopSlider from '../blocks/cooperations';
import ProgressScroll from '../blocks/progress';
import HighlightsSlider from '../blocks/highlights';
import Goals from '../blocks/goals';
import anchorButtons from '../blocks/anchor-button';
import Modal from '../blocks/modal';
import Timeline from '../blocks/timeline';
import Team from '../blocks/team';
import Menu from '../blocks/menu';
import MobileSlider from '../blocks/MobileSlider';
import PostFilter from '../blocks/post-filter';
import ContactForm from '../blocks/contact-form';
import ProjectPicker from '../blocks/project-picker';
import Select from '../inputs/select';
import SearchForm from '../blocks/search-form';
import RevealEls from '../blocks/revealEls';
import Map from '../blocks/smartmaps';
import MapProject from '../blocks/smartmapsproject';
import CareerHeader from '../blocks/career-header';
import CareerCosmosSlider from '../blocks/career-cosmosslider';
import CareerTestimonialSlider from '../blocks/career-testimonial-slider';
import CareerLogoSlider from '../blocks/career-logo-slider';
import CareerMap from '../blocks/career-map';
import CareerTrainee from '../blocks/career-trainee';
import CareerHelper from '../blocks/career-helper';
import CareerVueHelper from '../blocks/career-vuehelper';
import MakingOfVideos from '../blocks/making-of-videos';

export default {
  blocks: [
    {
      component: Goals,
      name: 'Goals',
    },
    {
      component: ProgressScroll,
      name: 'ProgressScroll',
    },
    {
      component: Map,
      name: 'Map',
    },
    {
      component: MapProject,
      name: 'MapProject',
    },
    {
      component: Timeline,
      name: 'Timeline',
    },
    {
      component: Modal,
      name: 'Modal',
    },
    {
      component: Team,
      name: 'Team',
    },
    {
      component: Menu,
      name: 'Menu',
    },
    {
      component: anchorButtons,
      name: 'anchorButtons',
    },
    {
      component: slider,
      name: 'Slider',
    },
    {
      component: MobileSlider,
      name: 'MobileSlider',
    },
    {
      component: ContactForm,
      name: 'ContactForm',
    },
    {
      component: ProjectPicker,
      name: 'ProjectPicker',
    },
    {
      component: PostFilter,
      name: 'PostFilter',
    },
    {
      component: SearchForm,
      name: 'SearchForm',
    },
    {
      component: CoopSlider,
      name: 'CoopSlider',
    },
    {
      component: HighlightsSlider,
      name: 'HighlightsSlider',
    },
    {
      component: CareerHeader,
      name: 'CareerHeader',
    },
    {
      component: CareerCosmosSlider,
      name: 'CareerCosmosSlider',
    },
    {
      component: CareerTestimonialSlider,
      name: 'CareerTestimonialSlider',
    },
    {
      component: CareerLogoSlider,
      name: 'CareerLogoSlider',
    },
    {
      component: CareerMap,
      name: 'CareerMap',
    },
    {
      component: CareerTrainee,
      name: 'CareerTrainee',
    },
    {
      component: CareerHelper,
      name: 'CareerHelper',
    },
    {
      component: CareerVueHelper,
      name: 'CareerVueHelper',
    },
    {
      component: MakingOfVideos,
      name: 'MakingOfVideos',
    },
  ],
  inputs: [
    {
      component: Select,
      name: 'select',
    },
  ],
  init() {
    AOS.init({ once: true, duration: 1000, anchorPlacement: 'top-center' });
    window.addEventListener('load', AOS.refresh);
    if (navigator.userAgent.match(/Trident.*rv:11\./)) {
      $('body').addClass('ie11');
    }

    this.blocks.forEach((block) => {
      this.initBlock(block.name, block.component);
    });

    this.inputs.forEach((input) => this.initInput(input.name, input.component));

    const $sections = document.querySelectorAll('.anim');
    new RevealEls({
      $sections,
    });

    /**
     * WINDOM SCROLL
     */

    $(window).scroll(function () {
      var scrollPos = $(window).scrollTop();
      if (scrollPos > 0) {
        $('.nav-primary').addClass('affix');
        $('.mobile-menu').addClass('affix');
      } else {
        $('.nav-primary').removeClass('affix');
        $('.mobile-menu').removeClass('affix');
      }
    });
    $('.nh-accordion-image .btn-link').on('click', function (event) {
      if (window.innerWidth < 992) {
        var accordion = $('.nh-accordion-image');
        window.setTimeout(function () {
          $('body,html')
            .stop()
            .animate({ scrollTop: accordion.offset().top }, 1000);
        }, 100);
      }
    });
    $('.nh-icon-tiles .btn-link').on('click', function (event) {
      var accordion = $(this).parent().parent().parent();
      window.setTimeout(function () {
        $('body,html')
          .stop()
          .animate({ scrollTop: accordion.offset().top - 300 }, 1000);
      }, 100);
    });
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
  initBlock(name, Component) {
    $('[data-initblock="' + name + '"]').each((idx, el) => {
      const block = new Component(el);
      block.init();
    });
  },
  initInput(name, Component) {
    $(name).each((idx, el) => {
      const input = new Component(el);
      input.init();
    });
  },
};
