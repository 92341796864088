class CareerMap {
  constructor(el) {
    this.el = el;
  }

  init() {
    this.initCareerMap();
  }
  initCareerMap() {
    $('.k-video-map .map-wrapper a').click(function () {
      $($(this).attr('href')).modal('show');
    });
  }
}
export default CareerMap;
