import Swiper from 'swiper';

class MobileSlider {
  constructor(el) {
    this.el = el;
    this.swiper = null;
  }
  init() {
    let elSlidesPerView = parseInt(this.el.getAttribute('data-slidesPerView')) || 4;
    let slidesPerView = 1;
    let breakpoints = {
      1024: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      680: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    };
    if (elSlidesPerView === 5) {
      breakpoints = {
        1180: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        680: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
      }
    }
    if (elSlidesPerView === 3) {
      breakpoints = {
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        680: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
      }
    }
    const promise = new Promise(resolve => {
      this.swiper = new Swiper(this.el, {
          spaceBetween: 15,
          slidesPerView,
          breakpoints,
      });
      setTimeout(()=> {
        resolve()
      },1000)
    })
    promise.then(()=> {
      if(this.swiper && this.swiper.update) this.swiper.update()
    })
  }
}
export default MobileSlider;
