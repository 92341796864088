class CareerHeader {
  constructor(el) {
    this.el = el;
  }

  init() {
    this.initCareerHeader();
  }
  initCareerHeader() {
    $('.count').each(function () {
      var $this = $(this);
      jQuery({ Counter: 0 }).animate(
        { Counter: $this.attr('data-stop') },
        {
          duration: 1500,
          easing: 'swing',
          step: function (now) {
            $this.text(Math.round(now*10)/10).toLocaleString('de');
          },
        }
      );
    });
  }
}
export default CareerHeader;
