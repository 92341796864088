import Swiper from 'swiper';
class CareerTestimonialSlider {
  constructor(el) {
    this.el = el;
    this.swiper = null;
  }

  init() {
    this.initCareerTestimonialSlider();
  }
  initCareerTestimonialSlider() {
    this.swiper = new Swiper('.swiper-testimonial', {
      slidesPerView: 1,
      speed: 1500,
      navigation: {
        nextEl: '.swiper-button-next.testimonial',
        prevEl: '.swiper-button-prev.testimonial',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
    });
  }
}
export default CareerTestimonialSlider;
