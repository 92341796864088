export default class RevealEls {
    constructor({$sections = [], callback, type}) {
        this.$sections = $sections;
        this.callback = callback;
        this.type = type;
        this.scroll = this.scroll.bind(this);
        this.init();
        return this;
    }
    init() {
        this.listeners();
    }
    listeners() {
        if (window.AnimatedEl) {
            window.removeEventListener('scroll', this.scroll);
        }
        window.addEventListener('scroll', this.scroll);
        setTimeout(() => {
            this.scroll();
        }, 500);
    }
    scroll() {
        const { $sections } = this;
        if ($sections.length > 0) {
            $sections.forEach(section => {
                if (section) {
                    const { top } = section.getBoundingClientRect();
                    const _offsetTop =
                        top - (window.innerHeight - window.innerHeight / 6);
                    if (_offsetTop <= 0) {
                        if (!section.classList.contains('js--anim-in')) {
                            section.classList.add('js--anim-in');
                            if (this.callback !== undefined) {
                                this.callback();
                                //delete this;
                            }
                        }
                    }
                }
            });
        }
    }
    destroy() {
        window.removeEventListener('scroll', this.scroll);
    }
}
