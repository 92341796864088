/* eslint-disable */
class Map {
  constructor(el) {
    this.el = el;
  }
  init() {
    this.initMap();
  }
  initMap() {
    const elem = document.getElementById('mapcontact');
    if (elem) {
      ym.ready(function (modules) {
        const contactsHelper = window.contact_block_helper || {};
        const contacts = contactsHelper.contacts;
        const locations = contacts.map((contact) => {
          return {
            lat: parseFloat(contact.coordinates.lat),
            lng: parseFloat(contact.coordinates.lon),
          };
        });
        const contents = contacts.map((contact) => {
          let phoneNumberHtml = '',
            faxNumberHtml = '',
            contactNumbersMarkup = '';

          if (contact.phone_number) {
            phoneNumberHtml = `T ${contact['phone_number']} </br>`;
          }

          if (contact.fax_number) {
            faxNumberHtml = `F ${contact['fax_number']}`;
          }

          if (faxNumberHtml || phoneNumberHtml) {
            contactNumbersMarkup = `<div class='card--phone-fax'>
            <p>
              ${phoneNumberHtml}
              ${faxNumberHtml}
            </p>
          </div>`;
          }

          return `<div class="card-wrapper text-black">
          <div class='card--headline'>
            <h4>${contact['headline']}</h4>
          </div>
          <div class='card--title'>
            <h3>${contact['title']}</h3>
          </div>
          <div class='card--firm'>
            <p>${contact['firm_name']}</p>
          </div>
          <div class='card--address'>
            <p>${contact['address']}</p>
          </div>
          ${
            contact['pdf_url']
              ? `<div class="card--download">
              <a href="${contact['pdf_url']}" target="_blank">
                ${contact['file_download_text']}
              </a>
            </div>`
              : ''
          }
          ${contactNumbersMarkup}
          <div class='card--email'>
            <a href="mailto:${contact['email']}">
              ${contact['email']}
            </a>
          </div>
        </div>`;
        });
        var map = ym.map('mapcontact', {
          center: ym.latLng(52.511, 13.447),
          zoom: 5,
          style: 'darkmode',
          scrollWheelZoom: false,
        });
        var icon = new modules.provider.Icon({
          iconUrl:
            'https://www.art-invest.de/wp-content/themes/art-invest/resources/assets/images/Map-Marker.svg',
          iconRetinaUrl:
            'https://www.art-invest.de/wp-content/themes/art-invest/resources/assets/images/Map-Marker.svg',
          iconSize: [37, 48],
          iconAnchor: [15, 49],
          popupAnchor: [0, -48],
        });
        const marker = [];
        locations.forEach((location, indexL) => {
          marker['marker' + indexL] = window.marker =
            new modules.provider.Marker(
              ym.latLng(location.lat, location.lng)
            ).bindPopup(contents[indexL]);
          marker['marker' + indexL].setIcon(icon);
          marker['marker' + indexL].addTo(map);
        });
      });
    }
  }
}
export default Map;
