import Swiper from 'swiper';
import AOS from 'aos';
class HighlightsSlider {
  constructor(el) {
    this.el = el;
    this.swiper = null;
  }

  init() {
    this.initSlider();
  }
  initSlider() {
    this.swiper = new Swiper('.swiper-highlights', {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      on: {
        slideChangeTransitionStart: function () {
          $('.img-col').hide(0);
          $('.text-col').hide(0);
          $('.img-col').removeClass('aos-init').removeClass('aos-animate');
          $('.text-col').removeClass('aos-init').removeClass('aos-animate');
        },
        slideChangeTransitionEnd: function () {
          $('.img-col').show(0);
          $('.text-col').show(0);
          AOS.init();
        },
      },
    });
    $('.construction-toggle').click(function () {
      var $highlightsContent = $(
        '.nh-highlights .count-' + $(this).data('count')
      );
      $highlightsContent.on('show.bs.collapse', '.collapse', function () {
        $highlightsContent.find('.collapse.show').collapse('hide');
      });
      $('.construction-toggle').removeClass('disabled');
      $(this).addClass('disabled');
    });
    $('.show-intro').click(function () {
      var $highlightsContent = $(
        '.nh-highlights .count-' + $(this).data('count')
      );
      $highlightsContent
        .find('.btn-primary')
        .attr('aria-expanded', 'false')
        .removeClass('disabled');
      $highlightsContent.find('.collapse').removeClass('show');
      $highlightsContent.find('.intro-collapse').addClass('show');
    });
  }
}
export default HighlightsSlider;
