import Swiper from 'swiper';

class CoopSlider {
  constructor(el) {
    this.el = el;
    this.swiper = null;
  }

  init() {
    this.initSlider();
  }
  initSlider() {
    this.swiper = new Swiper('.swiper-coop', {
      loop: true,
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
        1400: {
          slidesPerView: 7,
          spaceBetween: 40,
        },
      },
      spaceBetween: 77,
      autoplay: {
        delay: 2500,
      },
      navigation: {
        nextEl: '.swiper-button-icon-next',
        prevEl: '.swiper-button-icon-prev',
      },
    });
  }
}
export default CoopSlider;
