class Menu {
  constructor(el) {
    this.el               = el;
    this.toggleDropdown   = this.toggleDropdown.bind(this)
    this.toggleMenu       = this.toggleMenu.bind(this)
    this.mobileMenuActive = false
  }
  init() {

    const btnMobileMenu = document.querySelector('.button-menu-mobile')
    btnMobileMenu.addEventListener('click', this.toggleMenu)

    this.anchorMenu();
    this.initMobileMenu();

    /*
      var position      = $(window).scrollTop();
      var header        = $('header').outerHeight();
      var firstSection  = $('.bg-block').outerHeight();
      var secondSection = $('.bg-highligh-two-blocks').outerHeight();

      $('.page-section').each(function(i) {
        if($(this).position().top <= position){
          $('.sub-menu li a.active').removeClass('active');
          $('.sub-menu li a').eq(i).addClass('active');
        }
      });
    }); */

    /*$(document).on('scroll', onScroll);

    $('.sub-menu li a').on('click', function (e) {
      e.preventDefault();
      $(document).off('scroll');

      $('.sub-menu li a').each(function () {
        $(this).removeClass('active');
      })
      $(this).addClass('active');

      var target  = this.hash,
          $target = $(target);
      $('html, body').stop().animate({
        'scrollTop': $target.offset().top,
      }, 2000, 'swing', function () {
        window.location.hash = target;
        $(document).on('scroll', onScroll);
      });
    });

    function onScroll(){
      var scrollPos = $(document).scrollTop();
      $('.sub-menu li a').each(function () {
        var currLink   = $(this);
        var refElement = $(currLink.attr('href'));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
          $('.sub-menu li a').removeClass('active');
          currLink.addClass('active');
        }
        else{
          currLink.removeClass('active');
        }
      });
    }*/
  }

  anchorMenu() {
    $(this.el).find('.sub-menu li a').click(function (e) {
      e.preventDefault();
      var normalLinks = $(this).attr('href');
      $('.active').removeClass('active');
      $(this).addClass('active');
      var anchor = $(this).attr('href').toLowerCase().replace(/\s/g, '-');
      $(this).attr('href', anchor);
      if(/#/.test(anchor)) {
        var target = $(this.hash);
            target = target.length ? target : $('[data-name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top - 220,
          }, 2000);
          return false;
        }
      } else {
        window.location.href = normalLinks;
      }
    });

    $(this.el).find('.menu-top-menu-container li a').click(function (e) {
      e.preventDefault();
      var normalLinks = $(this).attr('href');
      $('.active').removeClass('active');
      $(this).addClass('active');
      var anchor = $(this).attr('href').toLowerCase().replace(/\s/g, '-');
      $(this).attr('href', anchor);
      if(/#/.test(anchor)) {
        var target = $(this.hash);
          target = target.length ? target : $('[data-name=' + this.hash.slice(1) + ']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top - 220,
          }, 2000);
          return false;
        }
      } else {
        window.location.href = normalLinks;
      }
    });

    $('.mobile-menu li a').on('click', function() {
      // e.preventDefault();
      // var normalLinks = $(this).attr('href');
      $('.js--active').removeClass('js--active');
      // var anchor = $(this).attr('href').toLowerCase().replace(/\s/g, '-');
      // $(this).attr('href', anchor);
      // if(/#/.test(anchor)) {
      //   $('.mobile-menu .dropdown').css({'height': 0});
      //   var target = $(this.hash);
      //   target = target.length ? target : $('[data-name=' + this.hash.slice(1) + ']');
      //   if (target.length) {
      //     $('html,body').animate({
      //       scrollTop: target.offset().top,
      //     }, 2000);
      //     return false;
      //   }
      // } else {
      //   window.location.href = normalLinks;
      // }
    });
  }

  initMobileMenu() {
    const btns = document.querySelectorAll('.mobile-menu .dropdown-btn')
    btns.forEach(btn => {
      btn.addEventListener('click', this.toggleDropdown)
    })
  }

  updateDropdown(drop, btnDrop, isActive) {
    if (!isActive) {
      const { offsetHeight } = drop.children[0] ? drop.children[0] : 0
      drop.classList.add('js--active')
      btnDrop.classList.add('js--active')
      drop.style.height = offsetHeight + 'px'
    } else {
      btnDrop.classList.remove('js--active')
      drop.classList.remove('js--active')
      drop.style.height = 0 + 'px'
    }
  }

  updateOldDropdown(activeBtn) {
    if (activeBtn) {
      const activeDropDown = activeBtn.nextElementSibling
      activeDropDown.classList.remove('js--active')
      activeBtn.classList.remove('js--active')
      activeDropDown.style.height = 0
    }
  }

  toggleDropdown(e) {
    const btnDrop   = e.currentTarget
    const drop      = e.currentTarget.nextElementSibling
    const isActive  = drop.classList.contains('js--active')
    const activeBtn = document.querySelector('.mobile-menu .dropdown-btn.js--active')

    this.updateDropdown(drop, btnDrop, isActive)
    this.updateOldDropdown(activeBtn)

  }

  toggleMenu(e) {
    const btn        = e.currentTarget
    const mobileMenu = document.querySelector('.mobile-menu')
    if (!this.mobileMenuActive) {
      this.mobileMenuActive = true
      mobileMenu.classList.add('js--active')
      btn.classList.add('js--active')
    } else {
      this.mobileMenuActive = false
      mobileMenu.classList.remove('js--active')
      btn.classList.remove('js--active')
    }
  }
}

export default Menu;
