class Modal {
  constructor(el) {
    this.el = el;
  }
  init() {
    $('.load-external-video').on('click', function () {
      const iframe = $(this).data('iframe');
      $(this).parent().parent().parent().append(iframe);
      $(this).parent().parent().hide();
    });
    this.addVideoModalEventListeners();
  }
  addVideoModalEventListeners() {
    $('.video-modal').on('hidden.bs.modal', function () {
      $(this)
        .find('.js-youtube-iframe')
        .each(function () {
          this.contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            '*'
          );
        });

      $(this)
        .find('.js-vimeo-iframe')
        .each(function () {
          let data = { method: 'pause' };
          this.contentWindow.postMessage(JSON.stringify(data), '*');
        });
    });
  }
}
export default Modal;
