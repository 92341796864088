import Swiper from 'swiper';

class Slider {
  constructor(el) {
    this.el = el;
    this.swiper = null;
  }

  init() {
    this.initSlider();
    this.clickAnchor();
    this.clickDownloadButton();
  }
  initSlider() {
    this.swiper = new Swiper('.swiper-banner', {
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  clickAnchor() {
    $(this.el)
      .find('.arrow-down a')
      .click(function (e) {
        e.preventDefault();
        $('html, body').animate(
          {
            scrollTop:
              $('#banner-block').offset().top +
              $('#banner-block')[0].scrollHeight -
              165,
          },
          1000
        );
      });
  }

  clickDownloadButton() {
    const self = this;
    $(this.el)
      .parent()
      .find('.button-download')
      .click(function (e) {
        e.preventDefault();

        const imgSrc = $(self.el)
          .find('.swiper-slide-active .bg-banner img')
          .attr('src');
        let nameDownload = $(self.el)
          .find('.swiper-slide-active .bg-banner img')
          .attr('alt');
        if (!nameDownload) {
          nameDownload = imgSrc.substring(imgSrc.lastIndexOf('/') + 1);
        }
        let elDownload = document.createElement('a');
        elDownload.style.display = 'none';
        elDownload.setAttribute('href', imgSrc);
        elDownload.setAttribute('download', nameDownload);
        $(self.el)[0].appendChild(elDownload);
        elDownload.click();
        $(self.el)[0].removeChild(elDownload);
      });
  }
}
export default Slider;
