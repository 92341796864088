class ContactForm {

  /**
   * Constructor.
   * @param {DOMElement} el Element for the contact form.
   */
  constructor(el) {
    this.$el = $(el);
    this.i18n = window.i18n_contact_form || {};
    this.name = 'ContactForm';

    // this.init();


  }

  /**
   * Inits the Contact form
   */
  init() {
    console.log('CENAS');
    this.unwrapContactFormInputs();
    this.removeAutoCompleteFromInputs();
    this.addValidators();
  }

  /**
   * Adds all of the validators.
   */
  addValidators() {
    this.$el.find('input[type=text].wpcf7-validates-as-required').on('keyup', (event) => {
      const $element = event.target;
      this.validateEmptyInput($element);
    });

    this.$el.find('input[type=text].wpcf7-validates-as-required').on('change', (event) => {
      const $element = event.target;
      this.validateEmptyInput($element);
    });

    this.$el.find('input[type=email]').on('keyup', (event) => this.validateEmailInput(event));
    this.$el.find('input[type=email]').on('change', (event) => this.validateEmailInput(event));
    this.$el.find('input[name=phone]').on('keyup', (event) => this.validateTelInput(event));
    this.$el.find('input[name=phone]').on('change', (event) => this.validateTelInput(event));
    this.$el.parent('form').on('submit', (event) => this.validateAllInputs(event));
  }

  /**
   * Validates email input.
   * @param {DOMElement} input Input to check
   */
  validateEmailInput(e) {
    const $submitButton = this.$el.find('input[type=submit]');

    const $input = e.target;
    const value = $input.value;

    const $parent = $($input).parent();
    const $errorLabel = $parent.find('.js-form-error');

    if(this.isEmail(value)) {
      $parent.removeClass('label-error');
      $submitButton.prop('disabled', false);
      $errorLabel.html('');
      return true;
    }

    $submitButton.prop('disabled', true);
    $parent.addClass('label-error');
    $errorLabel.html(this.i18n.wrong_email);
    return false;
  }


  validateTelInput(e) {
    const $submitButton = this.$el.find('input[type=submit]');

    const $input = e.target;
    const value = $input.value;

    const $parent = $($input).parent();
    const $errorLabel = $parent.find('.js-form-error');

    if(this.isTel(value)) {
      $parent.removeClass('label-error');
      $submitButton.prop('disabled', false);
      $errorLabel.html('');
      return true;
    }

    $submitButton.prop('disabled', true);
    $parent.addClass('label-error');
    $errorLabel.html(this.i18n.wrong_tel);
    return false;
  }

  /**
   * Checks if an input field is empty.
   * @param {DOMElement} field Field to be validated
   */
  validateEmptyInput(field) {
    const $field = $(field);
    const $parent = $field.parent();
    const $errorLabel = $parent.children('.js-form-error');

    if(!$field.val()) {
      $parent.addClass('label-error');
      $errorLabel.html(this.i18n.required_field);
      return false;
    }

    $parent.removeClass('label-error');
    $errorLabel.html('');

    return true;
  }

  /**
   * Checks if a string is valid email.
   * @param {string} email Email to check
   */
  isEmail(email) {
    return /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(email);
  }

  isTel(tel) {
    return /^[0-9 ()+-]+$/.test(tel);
  }


  /**
   * Validates all inputs on form submit.
   * @param {Evnet} e Event
   */
  validateAllInputs(e) {
    const self = this;
    const $requiredFields = this.$el.find('input[type=text].wpcf7-validates-as-required,input[type=email].wpcf7-validates-as-required');
    let isValidForm = true;

    $requiredFields.each(function(index, field) {
      isValidForm = self.validateEmptyInput(field);
    });

    if(this.$el.find('input[type=checkbox]').is(':checked')) {
      isValidForm = isValidForm && true;
      this.$el.find('input[type=checkbox]').removeClass('checkbox-error');
    } else {
      isValidForm = false;
      this.$el.find('input[type=checkbox]').addClass('checkbox-error');
    }

    if(isValidForm) {
      $('#contact-form-modal').modal('show');
      return;
    }

    e.preventDefault();
  }

  /**
   * Removes the autocompletes from all the input forms.
   */
  removeAutoCompleteFromInputs() {
    this.$el.find('input, :input').attr('autocomplete', 'off');
  }

  /**
   * Unwraps the contact form from dumb span that ContactForm7 adds.
   */
  unwrapContactFormInputs() {
    this.$el.find('.wpcf7-form-control').unwrap();
    this.$el.find('.wpcf7-response-output').remove();
    this.$el.find('.ajax-loader').remove();
  }
}

export default ContactForm;
