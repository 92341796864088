import Swiper from 'swiper';
class CareerLogoSlider {
  constructor(el) {
    this.el = el;
    this.swiper = null;
  }

  init() {
    this.initCareerLogoSlider();
  }
  initCareerLogoSlider() {
    this.swiper = new Swiper('.swiper-logos', {
      breakpoints: {
        // when window width is >= 768px
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next.logo',
        prevEl: '.swiper-button-prev.logo',
      },
    });
  }
}
export default CareerLogoSlider;
