import Swiper from 'swiper';

class Timeline {
  constructor(el) {
    this.el = el;
    this.percentTime;
    this.tick;
    this.time = 2;
    this.progressBarIndex = 0;
    this.mySwiper = null;
    this.sliderBullets = null;
    this.swiperController;
    this.map;
  }

  init() {
    this.createHtmlToBubble();
    this.initSlider();
    this.run();
  }

  initSlider() {
    this.sliderBullets = new Swiper('.swiper-bullets', {
      spaceBetween: 0,
      slidesPerView: 7,
      loop: false,
    });

    this.mySwiper = new Swiper('.swiper-timeline', {
      sliderPerView: 1,
      loop: true,
      touchRatio: 0,
      thumbs: {
        swiper: this.sliderBullets,
      },
    });


    this.mySwiper.on('slideChange', () => {

      this.resetProgressbar();

      $('.swiper-slide.inProgress').removeClass('inProgress');
      const $bullets = $(this.el).find('.swiper-bullets .swiper-slide');

      this.progressBarIndex = this.mySwiper.activeIndex-1 !== $bullets.length ? this.mySwiper.activeIndex-1 : 0;
      if(this.progressBarIndex%7 === 0){
        this.sliderBullets.slideTo(this.progressBarIndex)
      }

      $bullets.each((i, element) => {
        if (i < this.progressBarIndex && this.progressBarIndex !== $bullets.length) {
          $(element).addClass('fillBar');
        } else {
          $(element).removeClass('fillBar');
        }
      });

      $('.swiper-bullets .swiper-slide').eq(this.progressBarIndex).addClass('inProgress');
      this.startProgressbar();
    })
  }

  createHtmlToBubble() {
    $(this.el)
      .find('.swiper-bullets .swiper-slide .progressBarContainer')
      .each(function(index) {
        var progress =
          '<div class="main-parent-progress-div"><div class="progressBar"><div class="bubble"></div><div class="inProgress inProgress' +
          index +
          '"></div></div>';
        $(this).html(progress);
      });
    $('.swiper-bullets .swiper-slide').eq(this.progressBarIndex).addClass('inProgress');
  }

  startProgressbar() {
    this.percentTime = 0;
    this.tick = setInterval(this.interval.bind(this), 7);
  }

  animation() {
    this.percentTime += 1 / this.time;
    $(this.el)
      .find('.swiper-bullets .swiper-slide .inProgress' + this.progressBarIndex)
      .css({ width: this.percentTime + '%' });
  }

  interval() {
    if (this.percentTime >= 100) {
      this.mySwiper.slideNext();
      /* this.resetProgressbar();
      this.startProgressbar();
      $('.swiper-slide.inProgress').removeClass('inProgress');
      $('.swiper-bullets .swiper-slide').eq(this.progressBarIndex).addClass('inProgress'); */

    } else {
      this.animation();
    }
  }

  resetProgressbar() {
    $(this.el)
      .find('.swiper-bullets .swiper-slide .inProgress')
      .css({ width: 0 + '%' });
    clearInterval(this.tick);
  }

  run() {
    this.startProgressbar();
  }
}
export default Timeline;
