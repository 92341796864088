/**
 * Responsible for all the select componenets.
 */
class Select {
  /**
   * Constructor.
   * @param {DOMElement} el Select box.
   */
  constructor(el) {
    this.el = el;
    this.selectBoxCssClass = 'select-box';
  }

  /**
   * Init function.
   */
  init() {
    this.createDesktopElement();
    this.addEventListeners();
  }

  /**
   * Creates the desktop element.
   */
  createDesktopElement() {
    const $element = $(this.el);
    const $parent  = $element.parent('label');

    let optionsHtml = '';

    const options = $element.find('option').map(function() {
      return {
        val: $(this).val(),
        label: $(this).text(),
      }
    }).get();

    options.forEach(option => {
      optionsHtml += `<div class="${this.selectBoxCssClass}__option" data-value="${option.val}">${option.label}</div>`;
    });

    const additionalCssClass = (this.el.classList.contains('anchor-block-select')) ? `${this.selectBoxCssClass}--anchor-buttons` : '';

    $parent.append(
      `<div class="${this.selectBoxCssClass} ${additionalCssClass}">
        <div class="${this.selectBoxCssClass}__label">
          ${options[0].label}
        </div>
        <div class="${this.selectBoxCssClass}__options">
          ${optionsHtml}
        </div>
      </div>`
    );
  }

  /**
   * Add the event listeners for the select box.
   */
  addEventListeners() {
    const $element      = $(this.el);
    const $parent       = $element.parent('label');
    const $selectBoxDiv = $parent.find(`.${this.selectBoxCssClass}`);

    $selectBoxDiv.find(`.${this.selectBoxCssClass}__option`).on('click', (e) => {
      const $target = $(e.target);
      const value = $target.data('value');
      const label = $target.text();

      $element.val(value).change();
      $selectBoxDiv.find(`.${this.selectBoxCssClass}__label`).text(label);
      $selectBoxDiv.removeClass('open');
      $parent.removeClass('open');

      if(!$selectBoxDiv.hasClass(`${this.selectBoxCssClass}--anchor-buttons`)) {
        return;
      }

      const targetElement = $(`[data-name="${value}"]`);

      if (targetElement.length) {
        $('html,body').animate({
          scrollTop: targetElement.offset().top,
        }, 1000);
        return false;
      }
    });

    $selectBoxDiv.find(`.${this.selectBoxCssClass}__label`).on('click', () => {
      $selectBoxDiv.toggleClass('open');
      $parent.toggleClass('open');
    });
  }
}

export default Select;
