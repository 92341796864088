export default class Sticky {
  constructor(options) {
    this.el       = options.el
    this.isActive = false
  }
  init() {
    this.addStickyNavigation()
  }
  /**
   * Adds the sticky navigation.
   */
  addStickyNavigation() {
    const $header          = $('body > header');
    const $filterBox       = $(this.el).find('.filter-box');
    const $btnToggle       = this.el.querySelector('.btn-filter');
    const $lensToggle      = this.el.querySelector('.js-search-box-lens');
    const $filterBoxParent = $filterBox.parent();
    /* if ($(window).outerWidth() <= 1200) {
      return;
    } */
    if($lensToggle) {
      $lensToggle.addEventListener('click', () => this.toogleAnim($filterBox))
    }


    $btnToggle.addEventListener('click', () => this.toogleAnim($filterBox))
    window.addEventListener('scroll', () => this.makeFilterBoxSticky($header, $filterBox, $filterBoxParent))
  }

  toogleAnim($filterBox) {
    let className     = 'js--active';
        this.isActive = !this.isActive
    if (this.isActive) {
      $filterBox.addClass(className)
    } else {
      $filterBox.removeClass(className)
    }
  }

  repositionSticky() {
    const $header          = $('body > header');
    const $filterBox       = $(this.el).find('.filter-box');
    const $filterBoxParent = $filterBox.parent();

    /* if ($(window).outerWidth() <= 1200) {
      return;
    } */

    this.makeFilterBoxSticky($header, $filterBox, $filterBoxParent);
  }

  /**
   * Makes the FilterBox sticky.
   * @param {DOMElement} $header          Site Header
   * @param {DOMElement} $filterBox       What to make sticky
   * @param {DOMElement} $filterBoxParent Parent of the filter box
   */
  makeFilterBoxSticky($header, $filterBox, $filterBoxParent) {
    /* if ($(window).outerWidth() <= 1200) {
      return;
    } */

    const { pageYOffset } = window;
    const $submenu        = $header.find('.current_page_item .sub-menu')
    const headerPosition  = pageYOffset + $header.height() + $submenu.height();
    const boxPosition     = $filterBoxParent.offset().top;

    if ($(this.el).find('.js-filter-comparator').length > 0) {
      if ($(this.el).find('.js-filter-comparator').children().length === 0) {
        $filterBox.css({ 'position': 'static' });
        return;
      }
    }

    if (headerPosition <= boxPosition) {
      $filterBox.css({ 'top': 0, 'position': 'absolute', 'bottom': 'auto' });

    } else if (headerPosition > boxPosition) {
      if (headerPosition >= boxPosition + $filterBoxParent.height() - $filterBox.outerHeight()) {
        $filterBox.css({ 'top': 'auto', 'bottom': 0, 'position': 'absolute' });
      } else {
        $filterBox.css({ 'position': 'fixed', 'bottom': 'auto', 'top': $header.height() + $submenu.height() });
      }
    }
  }
}
