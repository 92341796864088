class anchorButtons {
    constructor(el) {
      this.el = el;
    }
    init() {
      this.getNameAttr();
      this.anchorAction();
    }

    getNameAttr() {
      $('[data-name]').each(function(){
        var regexAttr = $(this).attr('data-name').toLowerCase().replace(/\s/g, '-')
        $(this).attr('data-name', regexAttr)
      });
    }

    anchorAction() {
      $(this.el).find('.buttons-wrapper a, .category__card a').click(function(e){
        e.preventDefault();
        var anchor = $(this).attr('href').toLowerCase().replace(/\s/g, '-');
        $(this).attr('href', anchor)
        var target = $(this.hash);
        target = target.length ? target : $('[data-name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top - $('header').outerHeight(),
          }, 1000);

          return false;
        }
      });
    }
}
export default anchorButtons;
