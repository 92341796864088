class MakingOfVideos {
  constructor(el) {
    this.el = el;
  }
  init() {
    this.handleVimeoConsent();
    this.addVideoModalEventListeners();
    this.showMoreVideos();
  }
  handleVimeoConsent() {
    $('.load-external-video').on('click', function () {
      const iframe = $(this).data('iframe');
      $(this).parent().parent().parent().append(iframe);
      $(this).parent().parent().hide();
    });
  }
  showMoreVideos() {
    $('.load-more').on('click', function () {
      $('.col-12.col-lg-4.col-xl-custom.d-none')
        .slice(0, 5)
        .removeClass('d-none');
      if ($('.col-12.col-lg-4.col-xl-custom.d-none').size() === 0) {
        $(this).hide();
      }
    });
  }
  addVideoModalEventListeners() {
    $('.video-modal').on('hidden.bs.modal', function () {
      $(this)
        .find('.js-youtube-iframe')
        .each(function () {
          this.contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            '*'
          );
        });

      $(this)
        .find('.js-vimeo-iframe')
        .each(function () {
          let data = { method: 'pause' };
          this.contentWindow.postMessage(JSON.stringify(data), '*');
        });
    });
  }
}
export default MakingOfVideos;
