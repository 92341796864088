import Sticky from './sticky';
import RevealEls from '../blocks/revealEls';
/**
 * Responsible for the team block.
 */
class ProjectPicker {
  /**
   * Constructor.
   * @param {DOMElement} el
   */
  constructor(el) {
    this.el = el;
    this.projectFilter = [];
    this.lastBgModifier = 'bg--left';
    this.page = 1;
    this.current = 0;
    this.completed = 0;
  }

  /**
   * Adds a category to the state.
   * @param {string} filter
   */
  addProjectFilter(projectFilter) {
    this.projectFilter.push(projectFilter);
  }

  /**
   * Removes a project filter.
   * @param {string} filter
   */
  removeProjectFilter(projectFilterRemove) {
    this.projectFilter = this.projectFilter.filter(
      (projectFilter) => projectFilter !== projectFilterRemove
    );
  }

  /**
   * Resets the filters.
   */
  resetFilters() {
    this.projectFilter = [];
  }

  /**
   * Resets the pagination of the picker.
   */
  resetPagination() {
    this.page = 1;
  }

  /**
   * Increases the pagination of the picker.
   */
  increasePagination() {
    this.page = this.page + 1;
  }

  /**
   * Initializes the project picker
   */
  init() {
    this.hidePaginationButton();
    this.requestProjects();
    this.addPickerEventListeners();
    this.addStickyNavigation();
  }

  /**
   * Hides the pagination button.
   */
  hidePaginationButton() {
    const $paginationButton = $(this.el).find('.js-load-more-projects');
    $paginationButton.hide();
  }

  /**
   * Adds the event listeners for the interaction between the things.
   */
  addPickerEventListeners() {
    const filterButtonClasses = [
      'js-button-filter-status',
      'js-button-filter-nutzungsarten',
      'js-button-filter-projektarten',
      'js-button-filter-proberty-types-en',
      'js-button-filter-regionen',
      'js-button-filter-regions-en',
      'js-button-filter-data-centre-en',
      'js-button-filter-status-en',
    ];

    filterButtonClasses.forEach((className) => {
      $(`.${className}`).on('click', (e) => {
        const $element = $(e.target);
        const value = $element.data('filter');

        if (value === 'all') {
          $(`.${className}`).removeClass('control-active');
          $element.addClass('control-active');
        } else {
          $(`.${className}`).removeClass('control-active');
          $(`.${className}.js-parent`).removeClass('control-active');
          $element.toggleClass('control-active');
        }

        if ($(`.${className}.control-active`).length === 0) {
          $(`.${className}.js-parent`).addClass('control-active');
        }

        $(`.${className}`).each((idx, el) => {
          const $el = $(el);
          const filter = $(el).data('filter');

          this.removeProjectFilter(filter);

          if ($el.hasClass('control-active') && filter !== 'all') {
            this.addProjectFilter(filter);
          }
        });

        if (!this.projectFilter || this.projectFilter.length === 0) {
          $('.js-all').addClass('control-active');
        } else {
          $('.js-all').removeClass('control-active');
        }

        this.resetPagination();
        this.requestProjects();
      });
    });

    $('.js-all').on('click', (e) => {
      if ($(e.target).hasClass('control-active')) {
        return;
      }

      this.resetFilters();
      this.resetPagination();

      $('button.filter').removeClass('control-active');
      $('.js-parent').addClass('control-active');
      $('.js-all').addClass('control-active');

      this.requestProjects();
    });

    const $loadMore = $(this.el).find('.js-load-more-projects');

    $loadMore.on('click', () => this.requestProjects());
  }

  /**
   * Gets the html markup for the project card.
   * @param {Object} project Project Model to get the card from.
   */
  getProjectCard(project, index) {
    let cardModifier = '';

    if (Number.isInteger(index / 5)) {
      let cssModifier = '';

      if (this.lastBgModifier === 'bg--left') {
        this.lastBgModifier = 'bg--right';
        cssModifier = 'bg--right';
      } else {
        cssModifier = 'bg--left';
        this.lastBgModifier = 'bg--left';
      }

      $(this.el).find('.bgs').append(`<div class="bg ${cssModifier}"></div>`);
    }

    if (Number.isInteger(index / 4) || Number.isInteger((index + 1) / 4)) {
      cardModifier = 'mix--margin-left';
    }

    return `<div class="mix col-md-6 ${
      Number.isInteger(index / 4) && index > 2 ? 'pseudo-left' : ''
    } ${cardModifier}">
        <figure class="anim">
          <figcaption>
            <a href="${project.link}">
              <span>${project.title.rendered}</span>, <span>${
      project.place
    }</span>
              <h3>
                ${project.subtitle}
              </h3>
            </a>
          </figcaption>
          <a href="${project.link}">
            <img class="b-lazy" src="${project.featured_image}" />
          </a>
        </figure>
      </div>`;
  }

  /**
   * Updates the pagination button (hides/shows it).
   * @param {int} totalPages Total pages that exist in the latest request.
   */
  updatePaginationButton(totalPages) {
    console.log('this.page ' + this.page);
    console.log('total ' + totalPages);
    const $paginationButton = $(this.el).find('.js-load-more-projects');

    if (this.page >= totalPages) {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
      $paginationButton.hide();
      return;
    }

    $paginationButton.show();
  }

  /**
   * Adds the sticky navigation.
   */
  addStickyNavigation() {
    new Sticky({
      el: this.el,
    }).init();
  }

  /**
   * Renders the results from the AJAX request.
   * @param {array} projects Projects from the API response.
   */
  renderResults(projects) {
    const $container = $(this.el).find('.js-projects-container');
    const $containerCurrent = $(this.el).find('.js-project-current');
    const $containerCompleted = $(this.el).find('.js-project-completed');
    const $containerElse = $(this.el).find('.js-project-else');
    const childCount = $container.children().length;

    if (this.page === 1) {
      $(this.el).find('.bgs').html('');
    }

    let result = '';
    let resultCurrent = '';
    let resultCompleted = '';
    let current = 0;
    let completed = 0;
    projects.forEach((project, i) => {
      if ($.inArray(423, project.projekt_filter) !== -1) {
        resultCurrent += this.getProjectCard(project, i + 1 + childCount);
        current++;
      } else if ($.inArray(424, project.projekt_filter) !== -1) {
        resultCompleted += this.getProjectCard(project, i + 1 + childCount);
        completed++;
      } else {
        result += this.getProjectCard(project, i + 1 + childCount);
      }
    });
    if (current === 0) {
      $('.js-current').hide();
      $('.js-project-current').hide();
    } else {
      $('.js-current').show();
      $('.js-project-current').show();
    }
    if (completed === 0) {
      $('.js-completed').hide();
      $('.js-project-completed').hide();
    } else {
      $('.js-completed').show();
      $('.js-project-completed').show();
    }
    if (this.page === 1) {
      $containerCurrent.html(resultCurrent);
      $containerCompleted.html(resultCompleted);
      $containerElse.html(result);
      return;
    }

    $containerCurrent.append(resultCurrent);
    $containerCompleted.append(resultCompleted);
    $containerElse.append(result);
  }

  /**
   * Requests the projects from the wordpress rest API.
   */
  requestProjects() {
    const self = this;

    let params = {
      page: this.page,
    };

    if (this.projectFilter && this.projectFilter.length > 0) {
      params['project_filter'] = this.projectFilter.join(',');
    }

    $.ajax({
      url: '/wp-json/wp/v2/projekt?lang=' + window.ajax_object.curr_lang,
      data: params,
      success: function (response, status, request) {
        const totalPages = request.getResponseHeader('x-wp-totalpages');

        self.updatePaginationButton(totalPages);
        self.renderResults(response);
        self.increasePagination();

        new Sticky({
          el: self.el,
        }).repositionSticky();

        const animEl = self.el.querySelectorAll('.anim');
        new RevealEls({
          $sections: animEl,
        });
      },
    });
  }
}

export default ProjectPicker;
