import * as ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginIndicator } from 'scrollmagic-plugins';
class ProgressScroll {
  constructor(el) {
    this.el = el;
  }

  init() {
    this.initLoadmore();
    this.initScrolling();
  }
  initLoadmore() {
    var totalProgressCols = $('.nh-progress-tiles .progress-col').length;
    var progressOffset = 4;
    $('.load-more-progresses').click(function () {
      if (progressOffset < totalProgressCols) {
        $('.progress-col.d-none:lt(4)').each(function () {
          $(this).removeClass('d-none');
          progressOffset += 1;
        });
        if (progressOffset === totalProgressCols) {
          $('.load-more-progresses').hide();
        }
      }
    });
  }
  initScrolling() {
    ScrollMagicPluginIndicator(ScrollMagic);
    // init
    var controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: 'onLeave',
        duration: '150%', // this works just fine with duration 0 as well
        // However with large numbers (>20) of pinned sections display errors can occur so every section should be unpinned once it's covered by the next section.
        // Normally 100% would work for this, but here 200% is used, as Panel 3 is shown for more than 100% of scrollheight due to the pause.
      },
    });

    // get all slides
    var slides = document.querySelectorAll('.nh-progress-tiles');

    // create scene for every slide
    for (var i = 0; i < slides.length; i++) {
      new ScrollMagic.Scene({
        triggerElement: slides[i],
      })
        .setPin(slides[i], { pushFollowers: false })
        .addTo(controller);
    }
  }
}
export default ProgressScroll;
