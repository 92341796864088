class CareerHelper {
  constructor(el) {
    this.el = el;
  }

  init() {
    this.handleDocumentReady();
    this.handleAjaxComplete();
  }
  handleAjaxComplete() {
    $(document).ajaxComplete(function () {
      //open selected filter after ajax call
      //toggle filters on page load
      let params = {};
      window.location.search.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (str, key, value) {
          params[key] = value;
        }
      );
      if (params) {
        $.each(params, function (key) {
          $(`.searchandfilter li[data-sf-field-name="${key}"]`)
            .find('ul,h4')
            .toggleClass('show');
        });
      }
      //toggle filters
      $('.searchandfilter h4').on('click', function () {
        $(this).toggleClass('show');
        $(this).next('ul').toggleClass('show');
      });
      //switch layout for job results
      $('.js-show-gridview').on('click', function () {
        $(this).addClass('active');
        $('.js-show-listview').removeClass('active');
        $('.js-jobs-container .jobrow').hide();
        $('.js-jobs-container .jobcolumn').fadeIn();
      });
      $('.js-show-listview').on('click', function () {
        $(this).addClass('active');
        $('.js-show-gridview').removeClass('active');
        $('.js-jobs-container .jobcolumn').hide();
        $('.js-jobs-container .jobrow').fadeIn();
      });
    });
  }
  handleDocumentReady() {
    $(document).ready(function () {
      //toggle filters on page load
      let params = {};
      window.location.search.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (str, key, value) {
          params[key] = value;
        }
      );
      if (params) {
        $.each(params, function (key) {
          $(`.searchandfilter li[data-sf-field-name="${key}"]`)
            .find('ul,h4')
            .toggleClass('show');
        });
      }
      //toggle filters
      $('.searchandfilter h4').on('click', function () {
        $(this).toggleClass('show');
        $(this).next('ul').toggleClass('show');
      });
      //switch layout for job results
      $('.js-show-gridview').on('click', function () {
        $(this).addClass('active');
        $('.js-show-listview').removeClass('active');
        $('.js-jobs-container .jobrow').hide();
        $('.js-jobs-container .jobcolumn').fadeIn();
      });
      $('.js-show-listview').on('click', function () {
        $(this).addClass('active');
        $('.js-show-gridview').removeClass('active');
        $('.js-jobs-container .jobcolumn').hide();
        $('.js-jobs-container .jobrow').fadeIn();
      });
    });
  }
}
export default CareerHelper;
